<template>
    <v-container tag="section" fluid>
        <v-row class="mx-auto">
            <v-col cols="12">
                <h2>FAQ - Perguntas e Respostas</h2>
            </v-col>
            <question-answer :question="'1. Como valido os dados?'">
                <template v-slot:answer>
                    Na área ‘Visão Detalhada’ você tem a opção de aprovar ou reprovar alguma informação diretamente na
                    plataforma. Mas uma, caso prefira, é baixar os dados emformato Excel para análise. <br><br> O passo a
                    passo para localizar essa área e as regras de validação para cada indicador está descrito no Manual do
                    INDGER a partir da página 33, disponível na seção de 'Governança'.

                </template>
            </question-answer>

            <question-answer :question="'2. Quais são as principais regras de validação?'">

                <template v-slot:answer>
                    O critério principal é que os valores precisam ser <b>positivos</b> e <b>inteiros</b>. No entanto,
                    alguns indicadores
                    requerem específicas que podem ser encontradas de forma detalhada no manual do INDGER, a partir da
                    página 57.<br><br> Obs.: Encontre o manual na área de governança.

                </template>
            </question-answer>
            <question-answer :question="'3. Como visualizo as informações do meu tema?'">
                <template v-slot:answer>
                    Você tem duas opções para visualizar as informações do seu tema: <br> <br> 1. Para uma visão geral
                    acesse a seção de 'Validações', selecione o seu relatório e posteriormente o tema desejado; <br><br> 2.
                    Para obter detalhes mais específicos, dirija-se à área de 'Painéis Gerenciais', onde poderá escolher
                    entre
                    o 'Dashboard de Ligação com Obras (LNO)', o 'Dashboard de Serviços' ou o 'Dashboard de Faturamento'.
                </template>
            </question-answer>
            <question-answer :question="'4.Onde encontro a descrição de cada indicador?'">
                <template v-slot:answer>
                    As descrições dos indicadores são encontradas de maneira detalhada no manual do INDGER ou junto a lista
                    de responsáveis cada tema, disponibilizados na área de ‘Governança’. <br> <br> No manual encontra-se a
                    partir da página 54, já na lista estão descritos na aba ‘Comercial’, ‘Serviços’, ‘Serviços-auxiliares’,
                    ‘Tec_LD’, ‘Tec_Sub’ e ‘Tec_Al’.
                </template>
            </question-answer>
            <question-answer :question="'5. Dados com status de Alerta e/ou Erro, O que fazer?'">

                <template v-slot:answer>
                    Dados exibidos com sinal de alerta chamam a atenção para indicadores específicos. Por exemplo, um
                    <b>sinal de alerta</b> pode ser acionado quando o número de consumidores apresenta uma variação superior
                    a 3% em relação à média dos últimos 12 meses.<b> É importante observar que, nesses casos, a alteração
                        nos dados não é obrigatória.</b> <br><br> Já dados marcados com <b>sinal de erro</b> indicam que, de
                    alguma forma, as informações não atendem às regras de validação preestabelecidas. <b> Neste caso, é
                        obrigatório corrigir as informações para garantir a validação adequada.</b>
                </template>
            </question-answer>

            <question-answer :question="'6. Como saber quem são os validadores de cada tema?'">

                <template v-slot:answer>
                    Na área de ‘Governança’ encontramos uma tabela indicando os temas e todos os
                    responsáveis por eles.
                </template>
            </question-answer>

            <question-answer :question="'7. É possível verificar os indicadores por distribuidora?'">

                <template v-slot:answer>
                    É possível ter uma visão geral de todas as distribuidoras de duas maneiras, nos painéis
                    gerenciais e fazendo a mudança de empresa no menu lateral. No painel gerencial você
                    pode escolher entre os Dashboards de LNO, serviços e de faturamento. Dentro de cada um
                    deles basta acessar a aba ‘Geral’ para visualizar os indicadores.
                </template>
            </question-answer>

            <question-answer :question="'8. Quais os tipos de serviços que posso estar verificando na plataforma?'">

                <template v-slot:answer>
                    A plataforma apresenta todos os serviços do anexo IV da REN1000.
                </template>
            </question-answer>

            <question-answer :question="'9. É possível que outra pessoa valide os dados sobre o meu tema?'">

                <template v-slot:answer>
                    A validação está restrita ao titular do tema, sendo proibida a validação por terceiros. A
                    inclusão de um novo validador no tema somente será autorizada mediante formalização
                    da solicitação pelo executivo/gerente da área correspondente.
                </template>
            </question-answer>

            <question-answer :question="'10. Da para visualizar o ranking dos melhores/piores indicadores da Distribuidora?'">

                <template v-slot:answer>
                    É possível classificar os dados de serviços e indicadores comerciais. Para serviços, acesse a
                    área de 'Visão Detalhada', clique em 'Visualizar Valores' e, em seguida, clique no
                    código de cada serviço. Uma seta ao lado do indicador permitirá classificar os valores do maior para
                    o menor para o indicador escolhido.
                    <br><br> Da mesma forma, para indicadores comerciais, também na
                    'Visão Detalhada', clique em 'Visão por Município'. Escolha o indicador desejado para classificar por
                    município e clique
                    na seta para realizar a ordenação.
                </template>
            </question-answer>

        </v-row>

    </v-container>
</template>
  
<script>
import QuestionAnswer from './QuestionAnswer.vue';


export default {
    components: {
        QuestionAnswer: QuestionAnswer

    },
    name: "FAQ",
    data: () => ({
        //
    }),

}

</script>
  
<style></style>