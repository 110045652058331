<template>

            <v-expansion-panels class="mb-3">
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <h3>{{ question }}</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p>
                            <slot name="answer"></slot>
                        </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                
            </v-expansion-panels>

</template>
  
<script>

export default {
    name: "QuestionAnswer",
    props:{
        question:{
            type: String,
            default: ''
        },
    },

    data: () => ({
        //
    }),

}

</script>
  
<style></style>